import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import DarkMode from './DarkMode';
import useWindowSize from "./WindowsSize";
import MenuMobil from './MenuMobil';
import DesktopMobil from './DesktopMenu';
const items = [
    {
        nombre: "juannio",
        url: "www.juannio",
        estatus: true
    }
]
function Header (page) {
    
    const [pageActive, setPageActive] = useState(page.page)
    console.log(pageActive)
    const { width } = useWindowSize();
    return (  
        <div className=" sticky top-0 grid mb-5 z-40 opacity-90 justify-items-center w-[98.5vw]  bg-[#ffff]">
            {width < 768 ? <MenuMobil pageActive={pageActive} />: <DesktopMobil pageActive={pageActive} />}
        </div>
);
}

export default Header;