import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import {set_project} from '../../services/administrator'



const CreateProject = () => {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    img: [],
    title: '',
    descripcion: '',
    tecnologias: [],
    platfors: [],
    url: '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleTecnologiasChange = (event, values) => {
    
    setFormValues((prevValues) => ({
      ...prevValues,
      tecnologias: values,
    }));
  };


  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    
    setFormValues((prevValues) => ({
      ...prevValues,
      img: [...prevValues.img, ...files],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formDate = new FormData(form);

    handleClose();
    let response = set_project(formDate)

    
  };

  const StyledButton = styled(Button)`
    margin-top: 16px;
  `;

  return (
    <div>
      <StyledButton
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Crear Nuevo Proyecto
      </StyledButton>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <form onSubmit={handleSubmit} className='grid gap-2' enctype="multipart/form-data">
              <Stack spacing={2} className='border border-[#94a3b8] p-2 rounded'>
                  <p>Imagen de portada</p>
                  <input
                    type="file"
                    className='w-auto'
                    onChange={handleFileChange}
                    name="image_principal"
                  />
              </Stack>
              <TextField
                name="title"
                label="Título"
                value={formValues.title}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name="description"
                label="Descripción"
                value={formValues.descripcion}
                onChange={handleChange}
                fullWidth
              />
              <Stack spacing={2} className='border border-[#94a3b8] p-3 rounded'>
              <p>Seleccionar varias imagenes a la vez</p>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                name="images_related"
              />
              </Stack>
              <Autocomplete
                multiple
                name="technologies"
                options={['css', 'html', 'django']}
                value={formValues.tecnologias}
                onChange={handleTecnologiasChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="tecnologias"
                    label="Tecnologías"
                    fullWidth
                  />
                )}
              />
              <TextField
                name="url"
                label="URL"
                value={formValues.url}
                onChange={handleChange}
                fullWidth
              />
              <Button type="submit" variant="contained">
                Enviar
              </Button>
            
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateProject;
