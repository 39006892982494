import {BASE} from './base'
import axios from "axios"


export async function get_projects(){
    let url = BASE+'/administrator/projects';
    const response = await axios.get(url, {
        headers: {
            'Authorization':`Token ${localStorage.getItem('token')}`
        }
    })
    let data = response.data
        return data;
}


export async function set_project(data){
    let url = BASE+'/administrator/projects'
    axios.post(url, data, {
        headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
        }
    })
    .then(response =>{
        let data = response.data
        return data;
    })
    .catch(error => {
        console.log(error)
    })
}