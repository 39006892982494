import { Button } from "antd";
import NormalSwiper from "./Swiper";

function CardDescription(content) {
  return (
    <section className=" w-[100%] sm:h-[50vh] my-8 p-2 z-10 grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 sm:grid-cols-1 justify-items-center mb-24 ">
      <div className=" gap-2 sm:h-[15vh] lg:h-[50vh]  center-contents">
        <span className="font-lato lg:text-4xl 2xl:text-4xl sm:text-xl text-center w-[95%] dark:text-dark-05">
          {content.content.title}
        </span>
        <a href="https://wa.me/50236023914">
          <button
            href=""
            className="bg-action-call rounded-lg sm:p-1 lg:px-5 lg:py-3 drop-shadow-2xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 text-slate-50"
          >
            Obtener una cotización
          </button>
        </a>
      </div>
      <div className=" max-h-[100%]  sm:h-[24vh] lg:h-[50vh] sm:w-[100%] lg:w-[60%] 2xl:w-[60%] grid grid-cols-1 content-start  ">
        <NormalSwiper
          images={content.content.img}
          init={true}
          direction={"horizontal"}
          components={["effectcube", "autoplay"]}
        />
      </div>
    </section>
  );
}
export default CardDescription;
