import React from 'react';
import Typed from 'typed.js';

function TextTyped() {
    // Create reference to store the DOM element containing the animation
    const el = React.useRef(null);
  
    React.useEffect(() => {
      const typed = new Typed(el.current, {
        strings: [
                    'La implementación de aplicaciones específicas para el negocio mejora la experiencia del usuario', 
                    'Las aplicaciones eficientes optimizan los procesos internos y mejoran la eficacia general de las operaciones comerciales.'
        ],
        typeSpeed: 20,
      });
  
      return () => {
        // Destroy Typed instance during cleanup to stop animation
        typed.destroy();
      };
    }, []);
  
    return (
      <div className=" ">
        <span className='text-2xl dark:text-dark-06 text-[#000]' ref={el} />
      </div>
    );
  }

export default TextTyped;

