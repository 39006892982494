

export const firebaseConfig = {
    apiKey: "AIzaSyDqvfBnaWGoTPNsqvIJzgWwfIL00HzU4ww",
    authDomain: "myweb-2236d.firebaseapp.com",
    projectId: "myweb-2236d",
    storageBucket: "myweb-2236d.appspot.com",
    messagingSenderId: "117656761109",
    appId: "1:117656761109:web:4485c0d83b4d788785ef1c",
    measurementId: "G-E30P15873L"
  };
  export const openAI = {
    organization: "org-auOPieT2YjoEf6IB9wbWQu2O",
    apiKey: "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",
  }
  