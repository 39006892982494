import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import DarkMode from './DarkMode';
import FloatingText from "./Animationes/FloatingText";

function DesktopMobil(data) {
    const [pageActive, setPageActive] = useState(data.pageActive)

    return ( 
        <div className={" my-10 w-[100%] h-1  grid grid-cols-1 z-10 "}>
                <div className="w-[100%] grid grid-cols-4 text-white h-[100%] justify-items-center content-center text-2xl">
                    <Link to="/" className={'basis-1/2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 text-2xl cursor-pointer'}>DEVForge </Link>
                    {/* <Link to="/blog" className={(pageActive==="landing"? "":"text-yellow-insp ")+'basis-1/4  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer'}>Blog</Link> */}
                    {/* <Link to="/herramientas" className={(pageActive==="landing"? "":"text-yellow-insp ")+'basis-1/4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer'}>Herramientas y Juegos</Link> */}
                    <Link to="/portafolio" className={'basis-1/4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer'}>Portafolio</Link>
                    <Link to="/quienesSomos" className={'basis-1/4  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer'}>Acerca de Nosotros</Link>
                    <DarkMode />
                </div>
                
            </div>
     );
}

export default DesktopMobil;