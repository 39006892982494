import React, { Component } from "react";
import fondo1 from '../assets/fondo1.jpeg';
import CardCaracteristicas from './CardCaracteristicas';
import CardDescription from "../Components/CardDescription";
import build from '../assets/build.png';
import colaboracion from '../assets/diagrama de colaboracion.png';
import componentes from '../assets/diagrama-de-componentes.jpg';
import Header from "../Components/Header";
import TextTyped from "../Components/TextTyped";
import Cardlenguajes from './CardLenguajes';
import horizontal from "../assets/fondopc1.jpeg";
import Square from './../Components/Animationes/Square';
import Typed from 'typed.js';

class LandingPage extends Component {
  constructor() {
    super();
    this.handleScreenSizeChange = this.handleScreenSizeChange.bind(this);
    this.state = {
      card1: {
        title: 'Construye, evalua y adapta tu idea junto a nuestro equipo, en cualquier fase del desarrollo',
        img: [build, colaboracion, componentes],
        class:"",
        isMovil:false,
      },
      isScrolled: false // Estado para controlar la visibilidad del componente
    };
  }
  handleAminations() {
    setTimeout(() => {
      this.setState({class:"paralelogramo"})
    }, 500);
  }
  handleScreenSizeChange() {
      const breakpoint = 640; // Punto de quiebre para dispositivos pequeños en píxeles (sm en Tailwind CSS)
    
      if (window.innerWidth <= breakpoint) {
        this.setState({isMovil:true})
      } else {
      }
    }
  componentDidMount() {
    this.handleAminations()
    this.handleScreenSizeChange()
     // Agregar el evento de cambio de tamaño de pantalla
    window.addEventListener('resize', this.handleScreenSizeChange);
    window.addEventListener('scroll', this.handleScroll);
    
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { isScrolled } = this.state;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Si el desplazamiento es mayor a 100px, se oculta la imagen
    if (scrollTop > 100 && !isScrolled) {
      this.setState({ isScrolled: true });
    } else if (scrollTop <= 100 && isScrolled) {
      // Si el desplazamiento es menor o igual a 100px, se muestra la imagen nuevamente
      this.setState({ isScrolled: false });
    }
  };

  render() {
    const { isScrolled } = this.state;

    return (
    <>
    <Header page="landing" />
    <div className={`container w-full center-contents  overflow-hidden`}>
        {/* {this.state.isMovil && 
            <img className={`backgroud-img absolute top-0 z-0 sm:w-[100vw] dark:grayscale ${isScrolled ? 'hidden' : 'shower'}`}  src={fondo1} />}
        {! this.state.isMovil && 
            <img className={`backgroud-img absolute top-0 z-0 sm:w-[100vw]  dark:grayscale ${isScrolled ? 'hidden' : 'shower'}`}  src={horizontal} />} */}
        <span className={` w-[100%] h-[100vh] top-0 float  ${this.state.class}`}></span>
        <div className=" grid justify-items-center md:h-[40vh] xl:h-[78vh] content-start sm:mt-[5rem]">
          <section className=" title h-[100%] relative z-10 center-contents  w-[50%] ">
            <p id="text-forge" className="sm:text-sm md:text-8xl lg:text-8xl 2xl:text-8xl font-lato text-center  w-[80%] center-contents dark:text-dark-06 text-[#000] ">
              La forja del desarrollador
            </p>
            <br></br>
            
            <a href="https://wa.me/50236023914" className="mt-[5rem]">
              <button href="" className="bg-action-call rounded-lg sm:p-1 lg:px-5 lg:py-3 border-1 border-blue drop-shadow-2xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 text-slate-50" >
                Obtener una cotización
              </button>
            </a>
          </section>
          <br></br>
          <br></br>
          <br></br>
          <section className="mt-10 z-10">
          <TextTyped />
          </section>
        </div>
        
        <CardCaracteristicas />
        <Cardlenguajes />
        <CardDescription content={this.state.card1} />
        
      </div>
      {/* <Square /> */}
      {/* <div class="pulsating-rectangle absolute sm:top-[10%] lg:top-[15vh] xl:top-[30vh]  w-[55vw] h-[28vh] " ></div> */}

    </>
    )
  }
}

export default LandingPage;
