import { Button } from "antd";
import javascript from '../assets/js.png'
import python from '../assets/python.png'
import django from '../assets/django.png'
import react from '../assets/react.png'
import html5 from '../assets/html5.png'
import css from '../assets/css.png'
import ionic from '../assets/ionic.png'
import mui from '../assets/mui.png'
import typescript from '../assets/typescript.png'
import android from '../assets/android.png'
import responsive from '../assets/responsive.png'
import wordpress from '../assets/wordpress.png'
import bulma from '../assets/bulma.png'
import tailwind from '../assets/tailwind.png'
import boostrap from '../assets/bootstrap.png'
import {useState} from 'react'
import ModalScreen from '../Components/Modal';


function Project(project) {
    const [isActiveModal, setIsActiveModal] = useState(false)
    const [isMostDetails, setIsMostDetails] = useState(false)
    const icon = (name) => {
            if(name==="django"){
                return <img className="lg:h-[2.5rem]" src={django}/>
            }else if(name==="python"){
                return <img className="lg:h-[2.5rem]" src={python}/>
            }else if(name==="react"){
                return <img className="lg:h-[2.5rem]" src={react}/>
            }else if(name==="javascript"){
                return <img className="lg:h-[2.5rem]" src={javascript}/>
            }else if(name==="html5"){
                return <img className="lg:h-[2.5rem]" src={html5}/>
            }else if(name==="css"){
                return <img className="lg:h-[2.5rem]" src={css}/>
            }else if(name==="responsive"){
                return <img className="lg:h-[2.5rem]" src={responsive}/>
            }else if(name==="wordpress"){
                return <img className="lg:h-[2.5rem]" src={wordpress}/>
            }else if(name==="bulma"){
                return <img className="lg:h-[2.5rem]" src={bulma}/>
            }else if(name==="ionic"){
                return <img className="lg:h-[2.5rem]" src={ionic}/>
            }else if(name==="typescript"){
                return <img className="lg:h-[2.5rem]" src={typescript}/>
            }else if(name==="mui"){
                return <img className="lg:h-[2.5rem]" src={mui}/>
            }else if(name==="mobile"){
                return <img className="lg:h-[2.5rem]" src={android}/>
            }else if(name==="tailwind"){
                return <img className="lg:h-[2.5rem]" src={tailwind}/>
            }else if(name==="boostrap"){
                return <img className="lg:h-[2.5rem]" src={boostrap}/>
            }
        
    }

    const handleMouseOver = () => {
        console.log("hover")
        setIsMostDetails(true)
    }
    const handleMouseLeave = () => {
        console.log("hover")
        setIsMostDetails(false)
    }
    const openModal = () => {
        setIsActiveModal(true)
    }
    const closeModal = () => {
        setIsActiveModal(false)
    }
    return ( 
        <>
        {isActiveModal && <ModalScreen imagesextra={project.details.others} isActiveModal={isActiveModal} closeModal={closeModal} setIsActiveModal={setIsActiveModal}  />}
        <div className="">
            <div className=" grid sm:grid-cols-1  lg:grid-cols-2 p-2 gap-4 mb-8">
                <div className=" z-20 rounded-md relative  group/item grid justify-items-center"  onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <div className="absolute bg-[#aa9f9f5e] rounded-lg group/edit invisible group-hover/item:visible w-[100%] h-[100%] z-20 flex justify-center items-center">
                        <button onClick={openModal} class="rounded-lg  border-2 border-[#ffff] bg-[#030712] z-40 h-[3rem] w-[8rem]  hover:bg-slate-200  flex items-center justify-center opacity-100 " href="tel:{person.phone}">
                            <span class="group-hover/edit:text-gray-700 text-[#ffff] flex items-center justify-center ">Ver mas</span>
                        </button>
                    </div>
                    <img src={project.details.img} className=" z-0 p-2 max-h-[80vh]" alt={project.details.alt}  />
                </div>
                
                <article className="p-8  shadow-3xl lg:w-[80%] bg-bgCardlight rounded ">
                    <span className="text-4xl font-lato  dark:text-dark-01">{project.details.title}</span>
                    <p className="text-l my-4 dark:text-dark-01 text-justify">
                        {project.details.descripcion}
                    </p>
                    <div className="text-xl text-slate-400 grid grid-flow-col gap-4">
                        {project.details.tecnologias.map(itemList =>{
                            return <div className=" text-l text-slate-400 dark:text-[#fff] h-[2.5rem]">{icon(itemList)}</div>
                        })}
                    </div>
                    <div className="flex gap-2 my-6 ">
                        {project.details.url.length > 0 ? <a href={project.details.url}><Button className="dark:text-darkTextColor ">Ir al sitio</Button></a>:""}
                    
                    <a class="lg:invisible sm:visible border border-[#ffff] rounded center">
                            <Button class="group-hover/edit:text-gray-700 dark:text-[#ffff] text-[#ffff] flex items-center justify-center w-[6rem] " onClick={openModal}>Ver mas</Button>
                        </a>
                    </div>
                    
                </article>
            </div>
        </div>
        </>
    )
}

export default Project;