import React, { useState } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const DeleteModal = ({ elementId, onDelete }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(elementId);
    handleClose();
  };

  return (
    <div>
      <StyledButton variant="contained" color="secondary" onClick={handleOpen}>
        <DeleteIcon />
      </StyledButton>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
            outline: 'none',
          }}
        >
          <Typography variant="h6" gutterBottom>
            ¿Desea eliminar este elemento?
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleDelete}>
            Eliminar
          </Button>
          <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: '10px' }}>
            Cancelar
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
