import { MenuFoldOutlined, FolderOpenOutlined, InfoCircleOutlined, MenuUnfoldOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, Switch } from 'antd';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const { Header, Sider } = Layout;

const MenuMobil = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [darkMode, setDarkMode] = useState(document.documentElement.classList.contains("dark")? true: false);
  const location = useLocation();



  const handleOnClick = (e) => {
    setDarkMode( ! darkMode);
    if (darkMode) {
      document.documentElement.classList.remove('dark');
    } else {
      
      document.documentElement.classList.add('dark');
    }
  };

  const getMenuKey = () => {
    const pathname = location.pathname;
    if (pathname === '/') {
      return '1';
    } else if (pathname === '/portafolio') {
      return '2';
    } else if (pathname === '/quienesSomos') {
      return '3';
    }
    return '';
  };

  return (
    <>
      <Layout className="fixed left-0 z-40 h-[100%] ">
        <Sider trigger={null} collapsible collapsed={collapsed} className={collapsed ? 'menu' : 'menu-open z-40'}>
          <div className="demo-logo-vertical" />
          <br />
          <div className="grid grid-flow-col justify-items-center ">
            <Switch onChange={(e) => handleOnClick(e)}
              checkedChildren="Dark"
              unCheckedChildren="Light"
              className="centered"
              checked={darkMode} />
          </div>
          <br />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[getMenuKey()]}
            className="z-40"
            items={[
              {
                key: '1',
                icon: <HomeOutlined />,
                label: <Link to="/" className="basis-1/4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer">Home</Link>,
              },
              {
                key: '2',
                icon: <FolderOpenOutlined />,
                label: <Link to="/portafolio" className="basis-1/4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer">Portafolio</Link>,
              },
              {
                key: '3',
                icon: <InfoCircleOutlined />,
                label: <Link to="/quienesSomos" className="basis-1/4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer">Acerca de Nosotros</Link>,
              },
            ]}
          />
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: 'colorBgContainer' }}>
            <Button
              type="text"
              className="dark:text-[#fff] text-[#fff] bg-[#0D0702] grid content-center top-3"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 30,
                height: 35,
              }}
            />
          </Header>
        </Layout>
      </Layout>
      <div className='absolute dark:bg-drk-menu bg-menu-color w-[100vw] h-[4rem] top-0'>
      <Link
        to="/"
        className="dark:text-textDark basis-1/2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 text-2xl cursor-pointer absolute right-5 top-3"
      >
        DEVFORGE
      </Link>
      </div>
      
    </>
  );
};

export default MenuMobil;
