import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { css } from '@emotion/react';
import login from '../assets/login.jpeg';
import { useNavigate } from 'react-router-dom';
import {get_token, Login_django} from '../services/Login_api'

// Estilos personalizados para el componente
const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Column = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Login = (email, password) => {

  
  console.log("token: "+localStorage.getItem('token'))
  return Login_django({'email': email, 'password': password})
};

// Componente de inicio de sesión
const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLoginClick = (e) => {
    e.preventDefault();
    let result = Login(email, password);
    if(result){
        window.location='/administrador'
    }
    // Lógica para manejar el clic del botón "Acceder"
    // Aquí puedes realizar llamadas a una API, verificar credenciales, etc.
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);

    
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Container className='overflow-hidden w-[100%]'>
      <Column className=' '>
        <img
          src={login}
          alt="Imagen de inicio de sesión"
          css={css`
            max-width: 100%;
            width: 100%;
            height: auto;
            height: 10px;
          `}
        />
        <h2 className='absolute top-6 text-[#ffff] text-6xl w-80 '>Acceder al panel de administración</h2>
      </Column>
      <Column>
        <Form onSubmit={handleLoginClick} className='login-form w-80 grid grid-cols-1 p-4'>
          <h2>Login</h2>
          <TextField label="Correo electrónico" variant="outlined" value={email} onChange={handleEmailChange} />
          <TextField label="Contraseña" type="password" variant="outlined" value={password} onChange={handlePasswordChange} />
          <Button variant="contained" type="submit">
            Acceder
          </Button>
        </Form>
      </Column>
    </Container>
  );
};

export default LoginScreen;
