import js from '../assets/js.png'
import escritorio from '../assets/options/escritorio.png'
import moviles from '../assets/options/moviles.png'
import responsivo from '../assets/options/responsivo.png'
import { useEffect } from 'react'
import useWindowDimensions from '../Components/WindowsSize'


function Cardlenguajes() {
    const {height, width} = useWindowDimensions()
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    useEffect(()=>{
        
    })
    return ( 
    <>
        <section className="  w-[100%]  mb-3 mt-20 h-1/2">
            <article className=" w-[100%] rounded h-24 ">
                <div className=" sm:h-[20vh]  w-[100%]  rounded dark:bg-bgCardDark  font-lato text-[#ffff] justify-items-center">
                    <p className="p-3 dark:text-textDark sm:text-[12px] lg:text-[15px] text-center">Nos enorgullece ofrecer servicios de desarrollo web que trascienden las fronteras de la pantalla. Sumérgete en la excelencia del diseño web responsivo, móvil y para escritorio, donde cada línea de código se teje con precisión para crear experiencias cautivadoras y sin fisuras.</p>
                </div>
            </article>
            <article className=" grid sm:grid-cols-3 lg:grid-cols-3">
                    <div className="flex flex-col p-2 sm:w-24 gap-2 lg:w-96 sm:h-96 lg:h-[100%] rounded shadow-2xl dark:bg-dark-06">
                        <h2 className='h-8 text-center text-xl'>Aplicaciones para windows</h2>
                        <img src={escritorio} alt="programas para pc, aplicaciones para empresas, software de escritorio, software para mi negocio" />
                        <p>
                            Nuestro enfoque de desarrollo de software de escritorio te proporcionará la ventaja competitiva que necesitas. Transforma tu visión en una experiencia de usuario de escritorio impecable.
                        </p>
                    </div>
                    <div className="flex flex-col p-2 sm:w-24 gap-2 lg:w-96 sm:h-96 lg:h-[100%]   dark:bg-dark-06 shadow-2xl rounded">
                        <h2 className='h-8 text-center text-xl'>Aplicaciones Móviles</h2>
                        <img src={moviles} alt="aplicaciones android, aplicaciones para geolocalizacion, app para mi empresa o negocio" />
                        <p>
                            Convierte tu visión en una realidad tangible que los usuarios adorarán, aprovechando la potencia 
                            de la tecnología móvil. ¡Haz que tu marca destaque en la palma de sus manos!
                        </p>
                    </div>
                    <div className="flex flex-col p-2 sm:w-24 gap-2 lg:w-96 sm:h-96 lg:h-[100%]  dark:bg-dark-06 shadow-2xl rounded ">
                        <h2 className='h-8 text-center text-xl'>Aplicaciones Web</h2>
                        <img src={responsivo} alt="paginas web, pagina para mi negocio en linea, aplicación para gestion de empleados, ecomerce, vender en linea, comercio electronico" />
                        <p>
                            Nuestro equipo garantiza una presencia en línea impactante y accesible desde cualquier dispositivo. Aprovecha la versatilidad del diseño web responsivo para llegar a tu audiencia 
                            en todas partes. ¡Haz que tu presencia en la web sea inolvidable!
                        </p>
                    </div>
            </article>
        </section>
    </> 
    );
}

export default Cardlenguajes;