import juannio_subasta from '../assets/juannio_subasta.png';
import juannio_website from '../assets/juannio_website.png';
import kensa from '../assets/websites/kensa/kensa.png';
import Header from '../Components/Header';
import Project from './Project';

import k1 from '../assets/websites/kensa/k1.png'
import k2 from '../assets/websites/kensa/k2.png'
import k3 from '../assets/websites/kensa/k3.png'
import k4 from '../assets/websites/kensa/k4.PNG'
import k5 from '../assets/websites/kensa/k5.png'
import k6 from '../assets/websites/kensa/k6.png'
import k7 from '../assets/websites/kensa/k7.png'
import k8 from '../assets/websites/kensa/k8.png'
import k9 from '../assets/websites/kensa/k9.png'


import iphone from '../assets/iphone.png'



import j1 from '../assets/websites/juannio/j1.png'
import j2 from '../assets/websites/juannio/j2.png'
import j3 from '../assets/websites/juannio/j3.png'

import subasta0 from '../assets/websites/subasta/subasta0.png'
import subasta1 from '../assets/websites/subasta/subasta1.png'
import subasta2 from '../assets/websites/subasta/subasta2.png'
import subasta3 from '../assets/websites/subasta/subasta3.png'

import img1 from '../assets/websites/app01/1.png'
import img2 from '../assets/websites/app01/2.png'
import img3 from '../assets/websites/app01/3.png'
import img4 from '../assets/websites/app01/4.png'
import img5 from '../assets/websites/app01/5.png'
import img6 from '../assets/websites/app01/6.png'
import img7 from '../assets/websites/app01/7.png'
import img8 from '../assets/websites/app01/8.png'


import ao1 from '../assets/websites/ao01/ao1.png'
import ao2 from '../assets/websites/ao01/ao2.png'
import ao3 from '../assets/websites/ao01/ao3.png'
import ao4 from '../assets/websites/ao01/ao4.png'
import ao5 from '../assets/websites/ao01/ao5.png'
import ao6 from '../assets/websites/ao01/ao6.png'
import ao7 from '../assets/websites/ao01/ao7.png'
import ao8 from '../assets/websites/ao01/ao8.png'
import ao9 from '../assets/websites/ao01/ao9.png'
import aoweb from '../assets/websites/ao01/aoweb.png'


import pdf_principal from '../assets/websites/pdf-load/pdf-load-principal.png'
import pdf_load_1 from '../assets/websites/pdf-load/pdf-load-1.png'
import pdf_load_2 from '../assets/websites/pdf-load/pdf-load-2.png'



import rossy_principal from '../assets/websites/rossys/rossy-principal.png'
import rossy_1 from '../assets/websites/rossys/rossys-1.png'
import rossy_2 from '../assets/websites/rossys/rossys-2.png'
import rossy_3 from '../assets/websites/rossys/rossy-3.png'
import rossy_4 from '../assets/websites/rossys/rossys-4.png'

export default function Nosotros (){
    let data = [
        {
            img:rossy_principal,
            title:"Restaurante Rossy's On Wheels",
            descripcion: "El restaurante rossy on wheels nos ha confiado el diseño de su pagina web para su seccion de resturante rodante, este restaurante se ubica en Estados Unidos y requería un sitio para sus clientes que deseen encontrarlos por su tipo de comida y ubicación",
            tecnologias: ["css","responsive", "django","html5","python", "boostrap"],
            others: [rossy_1, rossy_2,rossy_3, rossy_4],
            url:"",
            alt:"pagina web para restaurante, sitio web para pantallas moviles y pc, paginas con SEO, aplicacion para restaurante"
        },{
            img:pdf_principal,
            title:"Extractor de archivos pdf, excel, word",
            descripcion: "Esta es una aplicación que permitirá al cliente extraer la información de los informes comerciales, facturas, reportes de pago, y registros de pagos de pensiones a empleados del sector publico, esta es una aplicación complementaria de un software mas grande de analisis de clientes para prestamos y generar un punteo de riesgo para cada cliente",
            tecnologias: ["css","responsive", "django","html5","python", "boostrap"],
            others: [pdf_load_1, pdf_load_2],
            url:"",
            alt: "extractor de PDF, aplicacion para extraer reportes de archivos, aplicacion para generacion de reportes"
        },{
            img:aoweb,
            title:"Website marketplace",
            descripcion: "Web site tipo marketplace que permite a los usuarios publicar sus productos y contactar con personas interesadas",
            tecnologias: ["css","responsive", "react","html5","typescript", "mui", "tailwild"],
            others: [ao1, ao2, ao3, ao4, ao5, ao6, ao7, ao8, ao9],
            url:"",
            alt: "market para negocio, market para venta de productos en linea, mercado en linea, software para publicar y vender productos"
        },{
            img:iphone,
            title:"Aplicacion marketplace",
            descripcion: "Una aplicacion tipo marketplace que permite a los usuarios publicar sus productos y venderlos",
            tecnologias: ["css", "react","html5","ionic","mobile", "mui", "typescript"],
            others: [img1, img2, img3, img4, img5, img6, img7, img8],
            url:"",
            alt: "aplicacion movil para mercado, aplicacion android para publicar productos, aplicacion android para mercado"
        },
        {
            img:kensa,
            title:"Kensa",
            descripcion: "Kensa es un proyecto de ciberseguridad que integra escaneres de red, de infraestructura, bases de datos, aplicaciones moviles, etc, y analiza productos basados en esos lenguajes e infraestructuras, tiene la capacidad de detectar vulnerabilidades y generar reportes y estadisticas basadas en las vulnerabilidades detectadas ",
            tecnologias: ["css","javascript","html5","react","python", "django", "tailwind"],
            others: [k1, k2, k3, k4, k5, k5, k6, k7, k8, k9],
            url:"",
            alt: "aplicacion para ciberseguridad, pagina para deteccion de malware en sitios web, aplicacion para prevenir haking, pagina para detectar vulnerabilidades en sitios web, detectar vulnerabilidades en servidores"
        },{
            img:juannio_subasta,
            title:"Subastas Juannio",
            descripcion: "Un proyecto web que cuenta con una galeria pública donde se habilita la opción de participar en la subasta, el sitio web cuenta con un dashboard privado donde se crean, editan y habilitan las subastas. los usuarios pueden participar y recibir notificaciones cuando su puja a sido superada por otro usuario",
            tecnologias: ["css","responsive", "javascript","html5","django", "bulma"],
            others: [subasta0, subasta1, subasta2, subasta3],
            url:"https://www.subasta.juannio.org",
            alt:"sitio web para subastas, sitio web para gestionar subastas y publicar productos, pagina web para realizar pujas"
        },{
            img:juannio_website,
            title:"Juannio",
            descripcion: "Un proyecto web de presentación de la ONG benefica, donde expone las obras de arte y las fechas de los próximos eventos",
            tecnologias: ["css","responsive", "javascript","html5","django", "bulma"],
            others: [j1, j2, j3],
            url:"https://www.juannio.org",
            alt: "landing page para mi negocio, landing page para mi organizacion, landing page para atraer clientes potenciales"
        },
    ]
    
    
    return(
        <>
        <article className="w-[100%] grid grid-cols-1 justify-items-center gap-y-6 mb-12">
            <Header page="herramientas" />
            <section className="mt-20 grid grid-cols-1 h-11 h-[100%] mx-2 px-4">
                <h1 className='sm:text-3xl lg:text-5xl font-lato dark:text-darkTextColor'>Proyectos recientes</h1>
                <p className='text-2xl dark:text-darkTextColor'>Revisa nuestros proyectos recientes, Haz click en cada imagen para ver mas detalles</p>
                {data.map(project =>{
                    return(
                        <Project  details={project}/>
                    )
                })}
                
            </section>
        </article>
        </>
    )
}