
import {BASE} from './base'
import axios from "axios"

export async function Login_django(data){
    let url = BASE+'/users/login'
    axios.post(url, data, {
        withCredentials: true
      })
    .then(response =>{
        const token = response.data.token;
        // Guarda el token en localStorage o en el estado de tu componente
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        localStorage.setItem('token', token);
        return true;
    })
    .catch(error =>{
        console.log("error:"+error)
        return false;
    })
}