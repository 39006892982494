import './App.css';
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import LandingPage from './LandingPage/LandingPage';
import Blog from './Blog/Blog.js';
import Nosotros from './Nosotros/Nosotros.js';
import Portafolio from './Portafolio/Portafolio.js'
import Footer from './Components/Footer';
import React, { Component } from 'react';
import AdminPanel from './Dashboard/Dashboard';
import TagManager from 'react-gtm-module';
import LoginScreen from './Login/Login';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DevForgeLandingPage from './Components/LandingsPages/DevForge';
import Bot from './Components/BotAsistenteFrontend';

class App extends Component {
  constructor() {
    super();
    this.state = {
      is_open_net: false,
      isLogin: localStorage.getItem('token') || "",
      tagManagerArgs: {
        gtmId: 'GTM-TK6KCPT'
      },
      question: ""
    }
    this.networkUrlsRef = React.createRef();
  }

  componentDidMount() {
    document.documentElement.classList.add('dark')

    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
    localStorage.theme = 'light'
  }

  handleNetwork() {
    this.setState({ is_open_net: !this.state.is_open_net })
  }

  rotateElement(element, duration) {
    var start = null;
    var elementRotation = 0;

    function step(timestamp) {
      if (!start) start = timestamp;
      var progress = timestamp - start;
      var rotation = (progress / duration) * 360;

      element.style.transform = `rotate(${rotation}deg)`;

      if (progress < duration) {
        window.requestAnimationFrame(step);
      } else {
        start = null;
        elementRotation += rotation;
        element.style.transform = `rotate(${elementRotation}deg)`;
      }
    }

    window.requestAnimationFrame(step);
  }

  handleQuestionChange(event) {
    this.setState({ question: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // Lógica para manejar el envío del formulario
    // Aquí puedes agregar tu lógica para enviar la pregunta a un servidor o realizar alguna acción adicional
    this.setState({ question: "" });
  }

  render() {
    TagManager.initialize(this.state.tagManagerArgs);

    const router = createBrowserRouter([
      {
        path: "/",
        element:  <LandingPage />
      },{
        path: "/landing2",
        element:  <DevForgeLandingPage />
      },{
        path: "/",
        element:  <LandingPage />
      },
      {
        path: "/administrador",
        element: this.state.isLogin ? <AdminPanel /> : <Navigate to="/login" />
      },
      {
        path: "/blog",
        element: <Blog /> 
      },
      {
        path: "/quienesSomos",
        element:  <Nosotros /> 
      },
      {
        path: "/portafolio",
        element: <Portafolio /> 
      },
      {
        path: "/login",
        element: <LoginScreen />
      },
    ]);

    return (
      <>
        <div className="w-full center-contents dark:bg-dark-03 h-auto bg-light-03">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RouterProvider router={router} />
          </LocalizationProvider>
          <div className={"networks-open absolute bottom-[8rem] right-10 grid grid-flow-cols z-50"} ref={this.networkUrlsRef}>
          <Bot />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default App;
