import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { styled } from '@mui/system';
import { red, green } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider } from 'antd';

const StyledButton = styled(Button)(({ theme, status }) => ({
  backgroundColor: status ? green[500] : red[500],
  color: "white",
  '&:hover': {
    backgroundColor: status ? green[700] : red[700],
  },
}));

const items = [
  {
    nombre: "juannio",
    url: "https://www.juannio.org",
    estatus: true
  },{
    nombre: "Subasta juannio",
    url: "https://www.subasta.juannio.org",
    estatus: true
  },{
    nombre: "juannio",
    url: "www.juannio",
    estatus: true
  },{
    nombre: "juannio",
    url: "www.juannio",
    estatus: true
  },
];

const TableProject = () => {
  const handleEdit = (item) => {
    // Lógica para editar el elemento
  };

  const handleDelete = (item) => {
    // Lógica para eliminar el elemento
  };

  return (
    <TableContainer className='p-5'>
    <Divider />
      <Table className='border border-1 rounded'>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Estatus</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.nombre}</TableCell>
              <TableCell><a href={item.url}>Ir al sitio</a></TableCell>
              <TableCell>
                <StyledButton status={item.estatus}>
                  {item.estatus ? 'Activo' : 'Inactivo'}
                </StyledButton>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleEdit(item)}>
                  <EditIcon />
                </Button>
                <Button onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableProject;
