import Header from "../Components/Header";
import perfilPht from "../assets/select2.jpg"
import backend from '../assets/backend.png'
import disenador from '../assets/disenadores.png'
import frontend from '../assets/fronted.png'
import innovacion from '../assets/innovacion.png'
import excelencia from '../assets/excelencia.png'
import pasion from '../assets/pasion.png'

function Nosotros() {
    return ( 
    <>
        <article className="grid grid-col-1  justify-items-center gap-y-6 w-[100%] lg:mx-8">
            <Header page="herramientas" />
            <section className=" mt-20 mb-10 grid w-[90%] h-[30rem]  ">
                <span className="text-4xl font-bold w-auto flex justify-center dark:text-darkTextColor ">Quienes Somos</span>
                
                <section className="bg-light-01 h-83 text-white sm:w-[100%] md:w-[100%]  z-0 rounded">
                    <p className=" text-[white] tracking-wide font-lato  sm:font-l lg:text-2xl sm:p-2 lg:p-8 dark:bg-dark-02 rounded-4"> 
                        <img src={perfilPht} className=" sm:m-2 bg-bgCardlight sm:float-left lg:h-[32vh]  lg-float-left  sm:w-36 lg:w-auto rounded-full " />
                        Es un placer darles la bienvenida a mi sitio web. Mi nombre es Gerson Olivares y me enorgullece presentarme como un Ingeniero en Sistemas de Información experimentado y comprometido con ofrecer soluciones tecnológicas innovadoras.
                        En colaboración con un equipo altamente capacitado y talentoso, nos dedicamos a asistirte en la creación de soluciones personalizadas que impulsen el crecimiento y el éxito de tu negocio. Nuestra amplia experiencia en el campo de la tecnología nos permite abordar cada desafío con un enfoque estratégico y creativo, brindando resultados de alta calidad.
                        Nos enorgullece ser tu aliado en todo el proceso, desde la concepción hasta la implementación y más allá. Reconocemos la importancia de comprender tus objetivos tecnológicos y nos esforzamos por brindarte soluciones a medida que se alineen con tu visión y necesidades específicas. Estamos dispuestos a escucharte y trabajar en estrecha colaboración contigo para asegurarnos de que cada paso se realice con precisión y eficiencia.
                    </p>
                </section>
            </section>
            <h1 className=" dark:text-darkTextColor">Nuestros colaboradores</h1>
            <section className=" lg:h-[45vh] sm:h-[100vh] grid sm:grid-cols-1 md:grid-cols-3 2xl:grid-cols-3  gap-4 ">
                <section className=" w-[100%] grid justify-center">
                    <img className="rounded-full bg-bgCardlight w-auto h-[15rem] shadow-2xl dark:bg-[#475569]" src={disenador} />
                    <span className="flex justify-center font-bold  dark:text-darkTextColor">Diseñadores UX/UI</span>
                </section>
                <section className=" w-[100%] grid justify-center">
                    <img className="rounded-full  bg-bgCardlight w-auto h-[15rem] shadow-2xl  dark:bg-[#475569]" src={backend} />
                    <span className="flex justify-center font-bold dark:text-darkTextColor">Desarrolladores Backend</span>
                </section>
                <section className=" w-[100%] grid justify-center">
                    <img className="rounded-full w-auto h-[15rem] bg-bgCardlight shadow-2xl dark:bg-[#475569]" src={frontend} />
                    <span className="flex justify-center font-bold dark:text-darkTextColor">Desarrolladores Frontend</span>
                </section>
            </section>
            <section className=" h-48  w-[90%] ">
                <span className="text-2xl font-bold dark:text-darkTextColor">Misión</span>
                <p className="text-xl dark:text-darkTextColor">
                Dotar a nuestros clientes de herramientas  que cumplan con altos estandares de calidad, que les permitan aprovechar las ventajas tecnologicas para mejorar los procesos empresariales
                </p>
            </section>
            <section className=" h-48  w-[90%]">
                <span className="text-2xl font-bold dark:text-darkTextColor">visión</span>
                <p className="text-xl dark:text-darkTextColor">
                    Crear soluciones de software escalables, con facilidad de uso,  que se integren a los procesos empresariales y aporten soluciones tecnologicas que permitan a la empresa centrarse en expandir el negocio.
                </p>
            </section>
            
            <section className="  w-[90%] my-4">
                    <span className="font-bold text-4xl flex justify-center dark:text-darkTextColor">Nuestros Valores</span>
                    <article className=" h-75 mt-5  w-[100%]">
                        <span className="flex justify-start text-xl font-bold dark:text-darkTextColor">Innovación</span>
                        <div className=" grid lg:grid-cols-1 sm:grid-cols-1 sm:gap-1 lg:gap-4 content-center">
                            <p className="lg:flex sm:text-m items-center lg:text-xl dark:text-darkTextColor ">
                                <span className="lg:w-[40rem] m-12 p-2">
                                    <img className="dark:bg-[#ffff]  sm:w-32 lg:w-80 lg:h-40 sm:float-right self-end justify-self-end" src={innovacion} />
                                </span>
                                Crear una cultura organizativa que promueva y apoye la creatividad, el espíritu emprendedor y la experimentación. La empresa alienta a los empleados a generar nuevas ideas, compartir conocimientos y aprender de los fracasos. Se brinda espacio y recursos para la investigación y el desarrollo, y se reconocen y recompensan los logros innovadores.
                            </p>
                        </div>
                    </article>
                    <article className=" lg:h-64 sm:h-90 sm:mt-5 w-[100%]">
                        <span className="flex justify-start text-xl font-bold dark:text-darkTextColor">Excelencia</span>
                        <div className="grid lg:grid-cols-1 sm:grid-cols-1 sm:gap-1 lg:gap-4 content-center">
                            <p className="lg:flex self-center  items-center sm:text-m lg:text-xl  dark:text-darkTextColor">
                                <span className="lg:w-[40rem] m-12 p-2">
                                <img className="dark:bg-darkTextColor sm:w-32 lg:w-80 sm:float-right lg:float-height lg:h-40 max-h-[100%] self-end justify-self-end" src={excelencia} />
                                </span>
                            Se manifiesta en la calidad de los productos que se entregan. Esto implica desarrollar software robusto, confiable y libre de errores que cumpla con los requisitos y expectativas de los clientes. Se implementan prácticas sólidas de desarrollo de software, como pruebas exhaustivas, revisión de código y aseguramiento de la calidad para garantizar que el producto final cumpla con los más altos estándares de calidad.
                            </p>
                        </div>
                    </article>
                    <article className=" lg:h-64 sm:mt-5  w-[100%]">
                        <span className="flex justify-start text-xl font-bold  dark:text-darkTextColor">Pasión</span>
                        <div className="grid lg:grid-cols-1 sm:grid-cols-1 sm:gap-1 lg:gap-4 content-center">
                            <p className="lg:flex sm:text-m lg:text-xl items-center text-justify dark:text-darkTextColor ">
                                <span className="lg:w-[40rem] m-12 p-2"><img className="dark:bg-[#ffff] sm:w-32 lg:w-80 sm:float-right lg:h-40 self-end justify-self-end" src={pasion} /></span>
                            Se refleja en el compromiso con la excelencia en el trabajo. \n Los empleados se esfuerzan por entregar productos y servicios de la más alta calidad, superando las expectativas de los clientes. La pasión los impulsa a buscar constantemente formas de mejorar y aprender, y a ser rigurosos en la ejecución de sus tareas.
                            </p>
                        </div>
                    </article>
                </section>
                <script>
                    ScrollReveal().reveal('.h-64');
                </script>
        </article>

    </> );
}

export default Nosotros;