import {React, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import juannio_subasta from '../../assets/juannio_subasta.png';
import juannio_website from '../../assets/juannio_website.png';
import kensa from '../../assets/websites/kensa/kensa.png';
import k1 from '../../assets/websites/kensa/k1.png';
import k2 from '../../assets/websites/kensa/k2.png';
import k3 from '../../assets/websites/kensa/k3.png';
import k4 from '../../assets/websites/kensa/k4.PNG';
import k5 from '../../assets/websites/kensa/k5.png';
import k6 from '../../assets/websites/kensa/k6.png';
import k7 from '../../assets/websites/kensa/k7.png';
import k8 from '../../assets/websites/kensa/k8.png';
import k9 from '../../assets/websites/kensa/k9.png';
import j1 from '../../assets/websites/juannio/j1.png';
import j2 from '../../assets/websites/juannio/j2.png';
import j3 from '../../assets/websites/juannio/j3.png';

import subasta0 from '../../assets/websites/subasta/subasta0.png';
import subasta1 from '../../assets/websites/subasta/subasta1.png';
import subasta2 from '../../assets/websites/subasta/subasta2.png';
import subasta3 from '../../assets/websites/subasta/subasta3.png';

import EditModal from './EditarProyecto';
import DeleteModal from './DeleteProject';
import CreateProject from './CreateProject';
import  {get_projects} from  '../../services/administrator'
import { BASE } from './../../services/base';

const StyledTableContainer = styled(TableContainer)`
  margin-top: 16px;
`;
const StyledTable = styled(Table)`
  min-width: 650px;
`;

const Proyecto = () => {
  const [data, setData] = useState([]);
  const  get_info_projects = async ()  => {
    const proyectos = await get_projects();
    console.log("resultados de la llamada")
    setData(proyectos)
    console.log(proyectos)
  };
  useEffect(()=>{
    get_info_projects()
  },[])
  const onEdit = () => {
  }
  return (
    <StyledTableContainer component={Paper}>
    <div className='grid grid-cols-2'>
      <CreateProject />
      {/* <UploadToGoogleDrive /> */}
    </div>
      <StyledTable aria-label="Data Table">
        <TableHead>
          <TableRow>
            <TableCell>Imagen</TableCell>
            <TableCell>Título</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Tecnologías</TableCell>
            <TableCell>Opciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <img src={BASE+item.image_principal} alt={item.title} />
              </TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>
              {item.technologies.map((tech, index) => (
                <div key={index}>
                  <img src={BASE+tech.icon} alt={tech.name} />
                </div>
              ))}
              </TableCell>
              <TableCell >
              <div className='grid gap-2'>
                    <EditModal element={item} onEdit={onEdit} />
                    <DeleteModal />
              </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};
export default Proyecto;
