
function  CardCaracteristicas() {
    return ( 
        <>
            <section className="w-[90%] ld:h-[470px] z-10 sm:h-[70vh] ">
                <div className="my-12 bg-[#ffffff8a] dark:bg-dark-04 rounded">
                    <p className="font-lato text-center dark:text-[#ffff] text-2xl tracking-wide center-contents">
                        Construimos soluciones de software escalable y  a la medida
                    </p>
                    <p className="text-slate-300 font-lato center-contents text-center dark:text-[#ffff] " >
                        Empleamos tecnicas de ultima generación para construir sitios web seguros y resistentes a errores 
                    </p>
                </div>
                <section className="w-[100%] h-[358px] max-h-[358px] sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3  grid gap-2 px-8 dark:text-[#ffff] ">
                    <div className=" shadow-2xl  border border-slate-300 dark:bg-[#bfbfbf] bg-bgCardlight rounded-lg sm:h-[20vh]  lg:h-[100%] grid grid-cols-1 content-end justify-items-centerhover:-translate-y-1 hover:scale-110  duration-300 " style={{backgroundImage:`url('./images/documentado.png')`, backgroundSize:'auto 100%', backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
                        <span className=" tags dark:text-[#000] bg-[#F7DF1E] ">Documentado</span>
                    </div>
                    <div className=" grid lg:grid-cols-2 sm:h-[20vh] lg:h-[100%] gap-2 ">
                        <div className=" shadow-2xl border border-slate-300 dark:bg-dark-05 bg-bgCardlight rounded-lg grid grid-cols-1 content-end justify-items-center hover:-translate-y-1 hover:scale-110  duration-300" style={{backgroundImage:`url('./images/ui.png')`, backgroundSize:'auto 100%', backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
                            <span className="tags dark:text-[#000] bg-[#F7DF1E]">UX/UI</span>
                        </div>
                        <div className=" shadow-2xl  border border-slate-300 dark:bg-dark-05 bg-bgCardlight rounded-lg  grid grid-cols-1 content-end justify-items-center hover:-translate-y-1 hover:scale-110  duration-300" style={{backgroundImage:`url('./images/eficiencia.png')`, backgroundSize:'auto 100%', backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
                            <span className="tags dark:text-[#000] bg-[#F7DF1E]">Eficiencia</span>
                        </div>
                        <div className=" shadow-2xl  border border-slate-300 dark:bg-dark-05 bg-bgCardlight rounded-lg col-span-2  grid grid-cols-1 content-end hover:-translate-y-1 hover:scale-110  duration-300" style={{backgroundImage:`url('./images/escalable.png')`, backgroundSize:'auto 100%', backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
                            <span className="tags dark:text-[#000] bg-[#F7DF1E]">Escalable</span>
                        </div>
                    </div>
                    <div className=" grid grid-cols-1 gap-2 lg:h-[100%]">
                        <div className=" shadow-2xl border border-slate-300 sm:h-[10vh] md:h-[100%] bg-bgCardlight dark:bg-[#bfbfbf] rounded-lg   grid grid-cols-1 content-end justify-items-center hover:-translate-y-1 hover:scale-110  duration-300" style={{backgroundImage:`url('./images/ingenieria.png')`, backgroundSize:'auto 100%', backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
                            <span className="tags dark:text-[#000] sm:w-[100%]  dark:text-shadow bg-[#F7DF1E]">Ingenieria de software</span>
                        </div>
                        <div className=" shadow-2xl  border border-slate-300 sm:h-[10vh] lg:h-[100%] bg-bgCardlight dark:bg-[#bfbfbf] rounded-lg  grid grid-cols-1 content-end justify-items-center hover:-translate-y-1 hover:scale-110  duration-300" style={{backgroundImage:`url('./images/soporte.png')`, backgroundSize:'auto 120%', backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
                                <span className="tags dark:text-[#000] bg-[#F7DF1E]">  Soporte </span>

                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

export default CardCaracteristicas;