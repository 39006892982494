import React from 'react';
import './../../assets/css/Square.css';

class Square extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: false
    };
  }
  
componentDidMount(){
    this.handleClick()
    }
  handleClick = () => {
    this.setState({ isAnimating: true });
  };

  render() {
    const { isAnimating } = this.state;

    return (
      <div className={`dark:hidden square-container z-10  ${isAnimating ? 'animate' : ''}`}>
        <div className="square h-[100vh]" ></div>
      </div>
    );
  }
}

export default Square;
