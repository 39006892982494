import React, {  useState } from 'react';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { BsRobot, BsFillPersonFill } from "react-icons/bs";
import { AiOutlineSend } from 'react-icons/ai';
import { Configuration, OpenAIApi } from "openai";
import {  welcomeMessages } from './Vars';
import { AiFillCloseCircle } from "react-icons/ai";
import {openAI} from "../keys.js";
import Chip from '@mui/material/Chip';

const Bot = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [showMessage, setShowMessage] = useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleFormOpen = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const configuration = new Configuration(openAI);
  
    const introMessage = {
      role: "system",
      content: "You are chatting with DEVGenius, a bot specialized in providing information and assistance related to our software development services. How can I assist you today?",
    };
  
    const messages = [
      ...chatHistory,
      { role: "user", content: question },
    ];
    setQuestion('');
    try {
      const openai = new OpenAIApi(configuration);
      const response = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
          introMessage,
          ...messages,
        ],
      });
  
      const data = response.data.choices[0].message.content;
      setChatHistory([
        ...messages,
        { role: "assistant", content: data },
      ]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setChatHistory([
        ...messages,
        { role: "assistant", content: "An error occurred, please try again." },
      ]);
    }
  };
  

  const getRandomWelcomeMessage = () => {
    const randomIndex = Math.floor(Math.random() * welcomeMessages.length);
    return welcomeMessages[randomIndex];
  };

  const handleClose = () => {
    setShowMessage(false);
  };
  const speakWithEmployer = () => {
    let a = document.createElement('a')
    a.target ="_blank";
    a.href="https://www.wa.me/50236023914";
    a.click();
  }
  return (
    <div className="fixed bottom-8 lg:right-8 sm:right-[0.25rem]  z-50">
      {showMessage  && (
        <>
          {!isFormOpen && (<span id="message-welcome" className="border border-2 border-slate-300 hover:border-indigo-300 bg-[#fff] p-4 rounded-lg shadow-lg absolute top-[-7rem] grid  w-[20rem] right-12">
          <AiFillCloseCircle className="relative btn-close cursor-pointer text-red" onClick={handleClose} />
          <p>{getRandomWelcomeMessage()}</p>
        </span>)}
        </>
      )}
      <Fab color="" onClick={handleFormOpen} aria-label="add" className="mb-2">
        <BsRobot className="w-[4rem] h-24" />
      </Fab>
      {isFormOpen   && (
        <div className="fixed bottom-8 lg:right-5 sm:right-[0.25rem] z-50">
          <div className="fixed lg:right-[1rem] bg-[#fff] border border-slate-300 hover:border-indigo-300 p-4 rounded-lg shadow-lg absolute bottom-10 grid gap-2 sm:w-[20rem] lg:w-[25rem] right-0">
            <div className="chat-history overflow-y-scroll h-60">
            <Chip label="Hablar con una persona." className='w-64' component="a" href='https://wa.me/50236023914' target="_blank" onClick={speakWithEmployer} color="success" variant="" />
              {chatHistory.map((message, index) => (
                <div key={index}>
                  {message.role === "assistant" ? <BsRobot /> : <BsFillPersonFill />}
                  <div className={`message message-${message.role}`}>
                    {message.content}
                  </div>
                </div>
              ))}
            </div>
            <form onSubmit={handleSubmit} className="flex items-center gap-2">
              <TextField
                label="Haz tu pregunta a nuestro chatbot"
                variant="outlined"
                placeholder='hacen tiendas virtuales?'
                onChange={handleQuestionChange}
                value={question}
                className="mr-2 w-[80%]"
              />
                  <LoadingButton
                  size="small"
                  loading={loading}
                  type="submit"
                  variant="outlined"
                >
                  <span><AiOutlineSend className="w-6 h-6" /></span>
                </LoadingButton>

            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bot;
