import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Typography,
  Container,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import BuildIcon from '@mui/icons-material/Build';
import Proyecto from './MysProyectos/Proyectos';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAuth, signOut } from "firebase/auth";
import DataGrid from './DataGrid';

const drawerWidth = 240;

const Root = styled('div')({
  display: 'flex',
});

const Appbar = styled(AppBar)({
  zIndex: (theme) => theme.zIndex.drawer + 1,
});

const DrawerContainer = styled('div')({
  width: drawerWidth,
});

const Content = styled('div')({
  flexGrow: 1,
  padding: (theme) => theme.spacing(3),
});

const Dashboard = () => {
  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Dashboard
      </Typography>
      {/* Contenido del dashboard */}
    </Container>
  );
};



const MisServicios = () => {
  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Mis Servicios
      </Typography>
      {/* Contenido de mis servicios */}
    </Container>
  );
};


const AdminPanel = () => {
  const [currentPage, setCurrentPage] = useState('dashboard');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const Logout = () => {
    const auth = getAuth();
    return signOut(auth).then(() => {
      localStorage.setItem("token","")
      window.location="/login"
    }).catch((error) => {
      // An error happened.
    });
  }
  const renderPage = () => {
    switch (currentPage) {
      case 'dashboard':
        return <DataGrid />;
      case 'misProyectos':
        return <Proyecto />;
      case 'misServicios':
        return <MisServicios />;
      default:
        return <DataGrid />;
    }
  };

  return (
    <Root className=''>
      <Appbar position="fixed">
        <Toolbar>
          <Typography variant="h6" noWrap>
            Admin Panel
          </Typography>
        </Toolbar>
      </Appbar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth },
        }}
      >
        <Toolbar />
        <DrawerContainer>
          <List>
            <ListItem
              button
              onClick={() => handlePageChange('dashboard')}
              selected={currentPage === 'dashboard'}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              onClick={() => handlePageChange('misProyectos')}
              selected={currentPage === 'misProyectos'}
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Mis Proyectos" />
            </ListItem>
            <ListItem
              button
              onClick={() => handlePageChange('misServicios')}
              selected={currentPage === 'misServicios'}
            >
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Mis Servicios" />
            </ListItem>
            <ListItem
              button
              onClick={() => Logout()}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Salir" />
            </ListItem>
          </List>
        </DrawerContainer>
      </Drawer>
      <Content className='h-[100%]'>
        <Toolbar />
        {renderPage()}
      </Content>
    </Root>
  );
};

export default AdminPanel;
