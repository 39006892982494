import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import NormalSwiper from './Swiper';


const ModalScreen = (data) => {
  const [open, setOpen] = useState(data.isActiveModal);
  const openModal = () => {
    setOpen(false)
    data.closeModal()
}
  const closeModal = () => {
    setOpen(false)
    data.closeModal()
}
  const handleClose = (e) =>{
    console.log("se cerro el modal")
    console.log(e)
  }
  return (
    <>
      <Modal
        title="DETALLE DEL PROYECTO"
        centered
        open={open}
        destroyOnClose={true}
        afterClose={(e)=> handleClose(e)}
        onOk={() =>  false}
        onCancel={() => closeModal()}
        okButtonProps={{ disabled: true }}
        width={1000}
      >
          <NormalSwiper images={data.imagesextra} init={false} direction={"horizontal"} components={[]}  />
      </Modal>
    </>
  );
};

export default ModalScreen;
