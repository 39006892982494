// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/effect-cards";
import "swiper/css/effect-flip";
import 'swiper/css/virtual';
import "swiper/css/effect-creative";
import "swiper/css/navigation";



import {Autoplay, Pagination, Navigation, EffectCube, EffectCards, EffectFlip, Virtual, EffectCreative } from 'swiper';



import { useState } from 'react';


function NormalSwiper(data){
    console.log(data.direction)
    const [init, setInit] = useState(data.init)
    let components = [Pagination , Navigation, Virtual]
    let direction = ""
    let effect=""
    let classes=""

    try {
        classes=data.classes
    } catch (error) {
    }


    data.components.map(component => {
        console.log(component)
        if(component ==="autoplay"){
            components.push(Autoplay)

        }else if(component === "effectcard"){
            components.push(EffectCards)
            effect="cards"
        }else if(component === "effectcreative"){
            components.push(EffectCreative)
            effect="creative"
        }else if(component === "effectflip"){
            components.push(EffectFlip)
            effect="flip"
        }else if(component === "effectcube"){
            components.push(EffectCube)
            effect="cube"
        }
    })
    try {
        direction = data.direction
    } catch (error) {
        direction= "horizontal"
    }
    return (
        <div className='w-[100%] h-auto'>
            <Swiper
            effect={effect}
            direction={direction}
            slidesPerView={1}
            centeredSlides={true}
            navigation={true}
            // QUBE
            cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
            }}
            // creative
            creativeEffect={{
                prev: {
                    shadow: true,
                    translate: ["-20%", 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            }}
            autoplay={{delay:4500, disableOnInteraction: true}}
            pagination={{clickable: true}}
            // autoHeight={true}
            modules={components}
            className={"max-w-[100%] w-max gap-2 h-auto"}
            >
            {
                data.images.length > 0 ? data.images.map((image, key) =>{
                    return <SwiperSlide  key={key} className={" max-w-[100%] w-max h-auto max-h-max bg-[#ffff]"+classes}><img class="bg-[#ffff] max-w-[100%] h-auto max-h-max left-0 rounded-sm" src={image} /></SwiperSlide>
                }): <SwiperSlide className={" max-w-[100%] w-max"}>No hay imagenes disponibles</SwiperSlide>
            }
        </Swiper>
        </div>
        
    );
};
export default NormalSwiper;