import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableProject from './MysProyectos/TableProjects';
import MojsExample  from './../Components/Animationes/Items';

const StyledGrid = styled(Grid)`
  flex-grow: 1;
`;
const data = [
    {
      titulo: "sitios activos", cantidad: 4
    }, {
      titulo: "Proyectos realizados", cantidad: 3
    }, {
      titulo: "Visitas totales", cantidad: 4
    }, 
    ]
const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DataGrid = () => {
  return (
    <StyledGrid container spacing={2}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                {item.titulo}
              </Typography>
              <Typography variant="h4" component="div">
                {item.cantidad}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
      <TableProject />
      {/* <MojsExample /> */}
    </StyledGrid>
  );
};

export default DataGrid;
