import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { css } from '@emotion/react';
import { Burst } from '@mojs/core';


const LandingPageContainer = styled('div')({
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor:"menta",
  backgroundSize: 'cover',
});

const Title = styled('h1')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '3rem',
  textAlign: 'center',
}));

const DevForgeLandingPage = () => {
  const theme = useTheme();

  const handleBurstAnimation = () => {
    const burst = new Burst({
      left: 0,
      top: 0,
      radius: { 0: 200 },
      angle: { 0: 90 },
      count: 5,
      children: {
        shape: 'circle',
        fill: theme.palette.secondary.main,
        duration: 2000,
      },
    });

    burst.play();
  };

  return (
    <LandingPageContainer
      onClick={handleBurstAnimation}
      css={css`
        cursor: pointer;
      `}
    >
      <Title>DevForge</Title>
    </LandingPageContainer>
  );
};

export default DevForgeLandingPage;
