
export const organization=process.env.REACT_APP_ORGANIZATION
export const apiKey=process.env.REACT_APP_API_KEY

export const welcomeMessages2 = [
    "¡Bienvenido! ¿Tienes alguna idea emocionante para una aplicación móvil?",
    "¡Hola! ¿Necesitas una aplicación para tu negocio o proyecto?",
    "¡Saludos! ¿Estás listo para llevar tu empresa al siguiente nivel con una aplicación móvil?",
    "¡Bienvenido! ¿Sabías que una aplicación móvil puede ayudarte a llegar a más clientes?",
    "¡Hola! ¿Tienes alguna pregunta sobre cómo podemos ayudarte con el desarrollo de aplicaciones móviles?",
    "¡Saludos! ¿Te gustaría tener una aplicación móvil personalizada para tu negocio?",
    "¡Bienvenido! ¿Quieres mejorar la experiencia de tus usuarios con una aplicación móvil?",
    "¡Hola! ¿Tienes en mente alguna funcionalidad específica para tu aplicación móvil?",
    "¡Saludos! ¿Quieres una aplicación móvil que se destaque entre la competencia?",
    "¡Bienvenido! ¿Estás listo para convertir tu idea en una aplicación móvil exitosa?",
    "¡Hola! ¿Necesitas una aplicación móvil que se adapte a tus necesidades?",
    "¡Saludos! ¿Te gustaría tener una aplicación móvil que refleje la identidad de tu marca?",
    "¡Bienvenido! ¿Tienes alguna pregunta sobre el proceso de desarrollo de aplicaciones móviles?",
    "¡Hola! ¿Estás buscando una solución móvil innovadora para tu negocio?",
    "¡Saludos! ¿Sabías que una aplicación móvil puede aumentar la productividad de tu empresa?",
    "¡Bienvenido! ¿Estás listo para sorprender a tus clientes con una aplicación móvil de calidad?",
    "¡Hola! ¿Necesitas una aplicación móvil que funcione en múltiples plataformas?",
    "¡Saludos! ¿Quieres una aplicación móvil con un diseño atractivo e intuitivo?",
    "¡Bienvenido! ¿Estás buscando un equipo de expertos en desarrollo de aplicaciones móviles?",
    "¡Hola! ¿Quieres una aplicación móvil que se destaque por su rendimiento y velocidad?",
    "¡Saludos! ¿Te gustaría tener una aplicación móvil que brinde una experiencia excepcional a tus usuarios?",
    "¡Bienvenido! ¿Estás listo para llevar tu negocio al mercado móvil?",
    "¡Hola! ¿Necesitas una aplicación móvil que se integre con otros sistemas?",
    "¡Saludos! ¿Sabías que una aplicación móvil puede mejorar la interacción con tus clientes?",
    "¡Bienvenido! ¿Tienes alguna pregunta sobre cómo monetizar tu aplicación móvil?",
    "¡Hola! ¿Estás buscando una solución móvil escalable y segura?",
    "¡Saludos! ¿Quieres una aplicación móvil que sea fácil de usar y administrar?",
    "¡Bienvenido! ¿Estás listo para impulsar tu negocio con una aplicación móvil personalizada?",
    "¡Hola! ¿Necesitas una aplicación móvil con características específicas para tu industria?",
    "¡Saludos! ¿Te gustaría tener una aplicación móvil que te ayude a destacar entre la competencia?",
    "¡Bienvenido! ¿Tienes alguna idea emocionante para una aplicación web?",
    "¡Hola! ¿Necesitas una página web moderna y funcional para tu proyecto?",
    "¡Saludos! ¿Estás listo para tener presencia en línea con una página web de calidad?",
    "¡Bienvenido! ¿Sabías que una página web puede ayudarte a expandir tu alcance?",
    "¡Hola! ¿Tienes alguna pregunta sobre cómo podemos ayudarte con el desarrollo web?",
    "¡Saludos! ¿Te gustaría tener una página web que atraiga y retenga a tus visitantes?",
    "¡Bienvenido! ¿Quieres mejorar la visibilidad de tu negocio con una página web profesional?",
    "¡Hola! ¿Tienes en mente algún diseño o funcionalidad específica para tu página web?",
    "¡Saludos! ¿Quieres una página web que refleje la identidad de tu marca?",
    "¡Bienvenido! ¿Estás listo para convertir tu idea en una página web exitosa?",
    "¡Hola! ¿Necesitas una página web que sea fácil de navegar y utilizar?",
    "¡Saludos! ¿Estás buscando una solución web personalizada y eficiente?",
    "¡Bienvenido! ¿Tienes alguna pregunta sobre el proceso de desarrollo web?",
    "¡Hola! ¿Estás buscando una página web que se adapte a tus necesidades y objetivos?",
    "¡Saludos! ¿Quieres una página web que se destaque por su diseño y usabilidad?",
    "¡Bienvenido! ¿Estás buscando un equipo de expertos en desarrollo web?",
    "¡Hola! ¿Quieres una página web optimizada para el rendimiento y la velocidad?",
    "¡Saludos! ¿Te gustaría tener una página web que brinde una experiencia excepcional a tus visitantes?",
    "¡Bienvenido! ¿Estás listo para llevar tu negocio al mundo digital con una página web?",
    "¡Hola! ¿Necesitas una página web que se integre con otros sistemas o servicios?",
    "¡Saludos! ¿Sabías que una página web puede ayudarte a captar nuevos clientes?",
    "¡Bienvenido! ¿Tienes alguna pregunta sobre cómo hacer crecer tu negocio en línea?",
    "¡Hola! ¿Estás buscando una solución web escalable y segura?",
    "¡Saludos! ¿Quieres una página web que sea compatible con dispositivos móviles y tablets?",
  ];
  export const welcomeMessages = [
    "Soy el bot DevGenius. ¿En qué puedo ayudarte hoy?",
    "Estamos aquí para resolver todas tus dudas. ¡Pregúntanos lo que necesites! Soy el bot DevGenius.",
    "¡Bienvenido! Soy el bot DevGenius. Cuéntanos cómo podemos hacer tu experiencia mejor.",
    "Estamos listos para brindarte el mejor servicio. ¿En qué puedo asistirte hoy? Soy el bot DevGenius.",
    "¿Necesitas información sobre nuestros productos o servicios? Estoy aquí para responder tus preguntas. Soy el bot DevGenius.",
    "¿Quieres descubrir todas las posibilidades que tenemos para ofrecerte? No dudes en preguntar. Soy el bot DevGenius.",
    "¡Hola! ¿En qué puedo orientarte para que obtengas el máximo provecho de nuestros servicios? Soy el bot DevGenius.",
    "Nuestro objetivo es hacerte la vida más fácil. ¿Cómo podemos hacerlo hoy? Soy el bot DevGenius.",
    "¿Buscas soluciones a tus problemas? ¡Estás en el lugar adecuado! Soy el bot DevGenius.",
    "No dudes en hacerme cualquier pregunta. Estoy aquí para ayudarte. Soy el bot DevGenius.",
    "¿Quieres saber cómo podemos satisfacer tus necesidades? Permíteme mostrarte todas nuestras capacidades. Soy el bot DevGenius.",
    "¿Necesitas asesoramiento? Estoy aquí para brindarte la mejor información. Soy el bot DevGenius.",
    "¡Hola! ¿En qué puedo asistirte hoy? No dudes en preguntar cualquier cosa. Soy el bot DevGenius.",
    "¿Quieres aprovechar al máximo nuestros servicios? Permíteme guiarte. Soy el bot DevGenius.",
    "Estoy aquí para ofrecerte toda la información que necesites. ¡Dispara tus preguntas! Soy el bot DevGenius.",
    "¿Te gustaría descubrir todas las ventajas de trabajar con nosotros? No dudes en preguntar. Soy el bot DevGenius.",
    "¿Buscas una solución rápida y eficiente? Estás en el lugar adecuado. Soy el bot DevGenius.",
    "Permíteme mostrarte cómo podemos mejorar tu experiencia con nosotros. Soy el bot DevGenius.",
    "¿Necesitas asistencia personalizada? Estoy aquí para atender tus necesidades específicas. Soy el bot DevGenius.",
    "No te preocupes, estoy aquí para responder todas tus inquietudes. Soy el bot DevGenius.",
    "¿Quieres conocer todas las características de nuestros productos? ¡Pregunta sin miedo! Soy el bot DevGenius.",
    "Permíteme explicarte cómo podemos ayudarte a alcanzar tus objetivos. Soy el bot DevGenius.",
    "¿Estás buscando respuestas rápidas? Estoy aquí para proporcionártelas. Soy el bot DevGenius.",
    "¿Necesitas ayuda con alguna de nuestras funciones? No dudes en preguntar. Soy el bot DevGenius.",
    "Estoy aquí para asegurarme de que obtengas toda la información que necesitas. Soy el bot DevGenius.",
    "¿Quieres descubrir cómo podemos facilitarte la vida? Permíteme mostrarte todo lo que podemos hacer. Soy el bot DevGenius.",
    "¿Necesitas información detallada sobre nuestros servicios? Estoy a tu disposición. Soy el bot DevGenius.",
    "Permíteme brindarte orientación sobre cómo aprovechar al máximo nuestra plataforma. Soy el bot DevGenius.",
    "¿Buscas soluciones personalizadas? Estoy aquí para escuchar y asesorarte. Soy el bot DevGenius.",
    "No dudes en hacerme todas las preguntas que tengas en mente. Estoy aquí para ayudarte. Soy el bot DevGenius.",
    "¿Quieres saber cómo nuestra empresa puede satisfacer tus necesidades? Pregunta sin compromiso. Soy el bot DevGenius.",
    "Estoy aquí para guiarte y asegurarme de que encuentres la solución perfecta. Soy el bot DevGenius.",
    "¿Necesitas más información antes de tomar una decisión? Estoy aquí para ayudarte en ese proceso. Soy el bot DevGenius.",
    "Permíteme explicarte cómo nuestras capacidades pueden mejorar tu negocio. Soy el bot DevGenius.",
    "¿Tienes alguna duda sobre nuestras políticas? Estoy aquí para aclararlas. Soy el bot DevGenius.",
    "¿Quieres descubrir todas las características exclusivas que ofrecemos? No dudes en preguntar. Soy el bot DevGenius.",
    "Estoy aquí para brindarte la asistencia necesaria en cada paso del camino. Soy el bot DevGenius.",
    "¿Necesitas ayuda con la configuración de nuestros servicios? Estoy aquí para guiarte. Soy el bot DevGenius.",
    "Permíteme mostrarte cómo podemos adaptarnos a tus necesidades individuales. Soy el bot DevGenius.",
    "¿Estás buscando una solución eficiente y asequible? Estás en el lugar correcto. Soy el bot DevGenius.",
    "Estoy aquí para brindarte todas las herramientas y recursos que necesitas. Soy el bot DevGenius.",
    "¿Tienes alguna pregunta sobre nuestros planes de precios? Estoy aquí para responderlas. Soy el bot DevGenius.",
    "Permíteme explicarte cómo nuestras soluciones pueden impulsar el crecimiento de tu empresa. Soy el bot DevGenius.",
    "¿Necesitas asesoramiento experto? Estoy aquí para ayudarte en cada paso del proceso. Soy el bot DevGenius.",
    "Estoy aquí para asegurarme de que obtengas el máximo valor de nuestros servicios. Soy el bot DevGenius.",
    "¿Listo para descubrir todas las ventajas de trabajar con nosotros? Pregunta todo lo que desees. Soy el bot DevGenius."
  ];
  
