import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const EditModal = ({ element, onEdit }) => {
  const [open, setOpen] = useState(false);
  const [editedElement, setEditedElement] = useState(element);
  const [formValues, setFormValues] = useState({tecnologias: editedElement.technologies});

  useEffect(() => {
      
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedElement((prevElement) => ({
      ...prevElement,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    onEdit(editedElement);
    handleClose();
  };
  const handleTecnologiasChange = (event, values) => {
    console.log(values)
    setFormValues((prevValues) => ({
      ...prevValues,
      tecnologias: values,
    }));
  };
  return (
    <div>
      <StyledButton variant="contained" color="primary" onClick={handleOpen}>
        <EditIcon />
      </StyledButton>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
            outline: 'none',
          }}
        >
          <Stack spacing={2}>
            <TextField
              name="title"
              label="Título"
              value={editedElement.title}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              name="descripcion"
              label="Descripción"
              value={editedElement.description}
              onChange={handleInputChange}
              fullWidth
            />
            <Autocomplete
                multiple
                name="technologies"
                options={['css', 'html', 'django']}
                value={formValues.tecnologias.map(tech => tech.name)}
                onChange={handleTecnologiasChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="tecnologias"
                    label="Tecnologías"
                    fullWidth
                  />
                )}
              />
            <TextField
              name="tecnologias"
              label="Tecnologías"
              value={editedElement.technologies.map(tech => tech.name).join(', ')}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              name="url"
              label="URL"
              value={editedElement.url}
              onChange={handleInputChange}
              fullWidth
            />
            {/* Agrega más campos del formulario según tus necesidades */}
            <Button variant="contained" onClick={handleEdit}>
              Guardar cambios
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EditModal;
